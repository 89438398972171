<template>
     <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
          <v-flex xs12 sm12>
            <v-card class="primary--border" outlined>
              <v-card-title class="title">
                Student List of Group -&nbsp;<strong>  {{ $route.query.name }}</strong>
                <v-spacer></v-spacer>
                <v-btn color="primary" small outlined v-if="form.selected_students.length > 0" @click="openMoal()">
                    <v-icon x-small> cameraswitch</v-icon> &nbsp;
                    Switch Group
                </v-btn>
                
              </v-card-title>
              <v-card outlined>
                <v-card-title class="pb-0 mb-0">
                  <v-flex xs3>
                    <v-select
                      :items="grades"
                      @change="getSections()"
                      class="pa-0"
                      label="Select grade"
                      outlined
                      dense
                      v-model="grade_id"
                    />
                  </v-flex>
                  <v-flex xs3>
                    <v-select
                      :disabled="sections.length < 1"
                      :items="sections"
                      class="pa-0"
                      label="Select section"
                      outlined
                      dense
                      v-model="section_id"
                    />
                  </v-flex>
                  <v-flex xs1 style="margin-top: 8px;">
                    <v-btn color="primary" class="mt-n8" outlined @click="getStudents()">Search</v-btn>
                  </v-flex>
                  <v-flex xs1>
                    <transition name="animate-css-transition" enter-active-class="animated fadeInRight"
                      leave-active-class="animated fadeOutRight">
                      <v-btn v-if="section_id || grade_id " id="staff_clear_filter" outlined color="primary"
                        text class="mt-n7-2 staff_clear_filter"
                        @click="section_id = '', grade_id = '', section = '', serial_number = '',getStudents()">
                        Clear
                      </v-btn>
                    </transition>
                  </v-flex>
                </v-card-title>
              </v-card>
              <v-data-table
                :key="compKey"
                :headers="headers"
                :items="form.items.data"
                :options.sync="pagination"
                :server-items-length="form.items.meta.total"
                :loading="form.loading"
                :footer-props="footerProps"

              >
                <template v-slot:item="{ index, item }">
                  <tr>
                    <td>
                        <v-checkbox @click="toogleStudent(item.enroll_code)"></v-checkbox>
                    </td>
                    <td class="text-left">{{ item.name }}
                      <div v-if="item.enroll_code">
                                            <span style="font-size: 10px; color: #666">(Enroll Code: {{ item.enroll_code
                                                }})</span>
                                        </div>
                    </td>
                    <td class="text-left">{{ item.email }}</td>
                    <td class="text-left">{{ item.grade_name }} / {{ item.section_name }}</td>
                    <!-- <td class="text-left">

                    </td> -->
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
          <v-dialog v-model="dialog" persistent max-width="400px">
            <v-card>
              <v-card-title class="primary white--text">
                <span class="title">Switch Group</span>
              </v-card-title>
              <v-card-text class="pb-1 pt-4">
                <v-form
                  ref="form"
                  v-model="valid"
                  :lazy-validation="lazy"
                  @submit.prevent="save"
                  @keydown.native="form.errors.clear($event.target.name)"
                  @keyup.enter="save"
                >
                  <v-text-field
                    autocomplete="off"
                    label="Current Group"
                    required
                    class="pa-0"
                    outlined
                    dense
                    v-model="current_group_name"
                    disabled
                  />

                  <v-select
                    :items="student_groups"
                    class="pa-0"
                    label="Select New Group *"
                    outlined
                    dense
                    v-model="form.new_group_id"
                    name="group"
                    :error-messages="form.errors.get('group')"
                    :rules="[(v) => !!v || 'group is required']"
                  />
                 
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="warning" text @click="dialog = false"
                  >Close</v-btn
                >
                <v-btn color="success" text @click="switchStudent()">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
      </v-layout>
     </v-container>
</template>

<script>
import { mapState } from "vuex";

import Form from "@/library/Form";
import Mixins from "@/library/Mixins";
export default {
  mixins: [Mixins],
  data: () => ({
    compKey: 0,
    valid: true,
    lazy: false,
    isLoading: false,
    dialog: false,
    student_groups: [],
    grades: [],
    sections: [],
    grade_id:'',
    section_id:'',
    current_group_name:'',
    form: new Form(
      {
        selected_students: [],
        new_group_id: '',

      },
      "/api/similar-grouped-students"
    ),
    // selected_students: [],
    headers: [
      { text: "#", align: "left", value: "id", sortable: false },
      { text: "Name", align: "left", value: "name", sortable: false },
      { text: "Email", align: "email", value: "email", sortable: false },
      { text: "Grade / Section", align: "grade_section", value: "grade_section", sortable: false },
      // { text: "Action", align: "right", sortable: false, width: 200 },
    ],
  }),
  watch: {
    pagination: function () {
      this.getStudents();
    },
  },
  computed: {
    ...mapState(["batch"]),
  },

  mounted(){
    this.getStudentGroup();
    this.getGrades();
    this.current_group_name = this.$route.query.name
    // this.form.current_group_id = 15

  },
  methods:{
    getStudents(params){
        let extraParams= "group_id=" + this.$route.params.group_id +'&gradeId=' + this.grade_id + '&sectionId=' + this.section_id;
        let query = [null, undefined].includes(params)
        ? this.queryString(extraParams)
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
      });
    },
    toogleStudent(enroll_code){
        if (this.form.selected_students.indexOf(enroll_code) > -1){
            this.form.selected_students.splice(
              this.form.selected_students.indexOf(enroll_code),
              1
            );
        }else{
            this.form.selected_students.push(enroll_code);

        }
    },
    getStudentGroup(){
      // this.student_groups.push({text: 'Ungrouped', value: 'ungrouped'});
      this.$rest.get("/api/student-group")
      .then(({data}) => {
        data.data.map((item) => {
            if(item.id !== this.$route.params.group_id) {
              this.student_groups.push({text: item.name, value: item.id});
            }
        });
      })
      if(this.$route.params.group_id !== 'ungrouped') this.student_groups.push({text: 'Ungrouped', value: 'ungrouped'});

      
    },

    switchStudent(){
      if (this.$refs.form.validate()) {
        this.form.store()
        .then((res) => {
          this.dialog = false
          this.compKey += 1;
          this.form.reset();
         this.getStudents();
        }).finally(() => {

        });
      }
    },
    getGrades() {
      this.$rest
        .get("/api/grades?rowsPerPage=20&sortBy=rank&descending=false")
        .then(({ data }) => {
          this.grades = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        });
    },
    getSections() {
      this.$rest
        .get(
          "/api/section?batch=" +
            this.batch.id +
            "&grade=" +
            this.grade_id
        )
        .then(({ data }) => {
          this.sections = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        });
    },
    openMoal(){
      this.form.current_group_id = this.$route.query.name 
      this.dialog = true;

    }
  }
};
</script>